
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component<DeleteModalComponent>({
  components: {
    ButtonComponent,
    Modal
  }
})
export default class DeleteModalComponent extends Vue {
  @Prop({ default: '' })
  size!: string;

  @Prop({ default: 'Title' })
  modalTitle!: string;

  messageBody = 'this';
  objectRef?: unknown;
  show = false;
  resolve: any = () => void 0;
  reject: any = () => void 0;

  close(): void {
    this.show = false;
  }

  public open(object: unknown, messageBody = 'this'): Promise<void> {
    this.show = true;
    this.objectRef = object;
    this.messageBody = messageBody;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  confirm(): void {
    this.resolve(this.objectRef);
    this.close();
  }

  cancel(): void {
    this.reject();
    this.close();
  }
}
