var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("modal", { attrs: { size: _vm.size }, on: { close: _vm.close } }, [
        _c(
          "div",
          { staticClass: "p-4 px-8" },
          [
            _c("div", { staticClass: "flex justify-between mb-4" }, [
              _c("div", { staticClass: "text-3xl capitalize" }, [
                _vm._v(_vm._s(_vm.modalTitle)),
              ]),
              _c(
                "div",
                {
                  staticClass: "text-2xl cursor-pointer",
                  on: { click: _vm.cancel },
                },
                [_vm._v("X")]
              ),
            ]),
            _vm._t("default", function () {
              return [_c("h4", [_vm._v(_vm._s(_vm.messageBody))])]
            }),
            _c(
              "div",
              { staticClass: "flex flex-row-reverse mt-4" },
              [
                _c("button-component", { on: { click: _vm.confirm } }, [
                  _vm._v(" Yes "),
                ]),
                _c(
                  "button-component",
                  { attrs: { border: false }, on: { click: _vm.cancel } },
                  [_c("span", [_vm._v("Cancel")])]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }